"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getYoutubeVideoId = exports.validateYoutubeUrl = exports.splitAtUsernamesAndLinks = exports.isVersionAGreaterOrEqualToVersionB = exports.generateUserGroup = exports.isExerciseTemplate = exports.getStrengthLevelFromPercentile = exports.isStrengthLevelEligibleExercise = exports.strengthLevelEligibleExerciseTemplateIds = exports.isExerciseLeaderboardEligible = exports.leaderboardEligibleExerciseTemplateIds = exports.isCompareExerciseSupported = exports.comparableExerciseTemplateIds = exports.numberToLocaleString = exports.numberWithCommas = exports.setVolume = exports.oneRepMax = exports.oneRepMaxPercentageMap = exports.workoutSetCount = exports.userExerciseSetWeight = exports.workoutDistanceMeters = exports.workoutReps = exports.workoutDurationSeconds = exports.removeAccents = exports.getClosestDataPointAroundTargetDate = exports.getClosestDataPointBeforeTargetDate = exports.findMapped = exports.stringToNumber = exports.forceStringToNumber = exports.formatDurationInput = exports.isValidFormattedTime = exports.customExerciseTemplateToExerciseTemplate = exports.isWholeNumber = exports.isNumber = exports.isValidUuid = exports.isValidPhoneNumber = exports.isValidWebUrl = exports.URL_REGEX = exports.isValidEmail = exports.secondsToWordFormatMinutes = exports.secondsToWordFormat = exports.secondsToClockFormat = exports.isValidUsername = exports.roundToWholeNumber = exports.roundToOneDecimal = exports.roundToTwoDecimal = exports.divide = exports.clampNumber = exports.num = void 0;
/**
 * Doesn't matter what you throw in the function it'll
 * always return a number. Non number values will return
 * 0.
 */
const num = (value) => {
    if (typeof value !== 'number')
        return 0;
    return value !== null && value !== void 0 ? value : 0;
};
exports.num = num;
const clampNumber = (value, limits) => {
    var _a, _b;
    const min = (_a = limits.min) !== null && _a !== void 0 ? _a : -Infinity;
    const max = (_b = limits.max) !== null && _b !== void 0 ? _b : Infinity;
    if (min > max) {
        return NaN;
    }
    return Math.max(min, Math.min(max, value));
};
exports.clampNumber = clampNumber;
const divide = (numerator, denominator) => {
    if (denominator === 0)
        return 0;
    return numerator / denominator;
};
exports.divide = divide;
const roundToTwoDecimal = (value) => Math.round(value * 100) / 100;
exports.roundToTwoDecimal = roundToTwoDecimal;
const roundToOneDecimal = (value) => Math.round(value * 10) / 10;
exports.roundToOneDecimal = roundToOneDecimal;
const roundToWholeNumber = (value) => Math.round(value);
exports.roundToWholeNumber = roundToWholeNumber;
const isValidUsername = (username) => {
    if (username.length > 20) {
        return false;
    }
    if (username.length < 3 ||
        username.startsWith('_') ||
        username.endsWith('_')) {
        return false;
    }
    const re = /^[a-z0-9_]+$/;
    return re.test(username);
};
exports.isValidUsername = isValidUsername;
/**
 * 01:25
 * 02:25:36
 */
const secondsToClockFormat = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds - hours * 3600) / 60);
    const remainderSeconds = seconds - hours * 3600 - minutes * 60;
    const paddWithLeadingZeros = (value) => {
        if (value.length === 0) {
            return '00';
        }
        if (value.length === 1) {
            return `0${value}`;
        }
        return value;
    };
    if (hours) {
        const hoursString = paddWithLeadingZeros(`${hours}`);
        const minString = paddWithLeadingZeros(`${minutes}`);
        const secString = paddWithLeadingZeros(`${remainderSeconds}`);
        return `${hoursString}:${minString}:${secString}`;
    }
    return `${paddWithLeadingZeros(`${minutes}`)}:${paddWithLeadingZeros(`${remainderSeconds}`)}`;
};
exports.secondsToClockFormat = secondsToClockFormat;
/**
 * 10s
 * 1min 10s
 * 2h 4min 45s
 */
const secondsToWordFormat = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds - hours * 3600) / 60);
    const remainderSeconds = Math.floor(seconds % 60);
    if (hours) {
        return `${hours}h ${minutes}min ${remainderSeconds}s`;
    }
    if (minutes) {
        return `${minutes}min ${remainderSeconds}s`;
    }
    return `${seconds}s`;
};
exports.secondsToWordFormat = secondsToWordFormat;
/**
 * 14min
 * 2h 4min
 */
const secondsToWordFormatMinutes = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds - hours * 3600) / 60);
    if (hours) {
        return `${hours}h ${minutes}min`;
    }
    return `${minutes}min`;
};
exports.secondsToWordFormatMinutes = secondsToWordFormatMinutes;
const isValidEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
};
exports.isValidEmail = isValidEmail;
/**
 * Matches strings with a base format of `domain.tld`.
 * - `domain` may contain letters, digits and dashes, but it can't begin or
 *   end with a dash
 * - `domain` may be prefixed once or multiple times with another `domain.`
 * - `tld` may be followed by `/path-to-page`
 * - the entire string may be prefixed by `https://` or `http://`
 */
exports.URL_REGEX = RegExp([
    // optionally begin with http(s)://
    /(https?:\/\/)?/,
    // followed by the domain:
    // - any combination of letters, numbers and dashes
    // - but without a dash at the start or end
    // - maximum 255 characters per domain
    // - each domain ends with a `.`
    /((?!-)[-a-z0-9]{1,255}(?<!-)\.)+/,
    // followed by the top-level domain
    /[a-z]{2,10}/,
    // optionally followed by a path
    /(\/[-a-z0-9@:%_+.~#?&/=]*)?/,
]
    .map((r) => r.source)
    .join(''));
const isValidWebUrl = (url) => {
    return RegExp(`^${exports.URL_REGEX.source}$`).test(String(url).toLowerCase());
};
exports.isValidWebUrl = isValidWebUrl;
/**
 * Check if a string is a valid phone number. A valid phone number is a string
 * that starts with a '+' followed by 9-15 digits with no spaces. This is the
 * format that we store in the database as well as what the Twilio API expects.
 *
 * @example
 * // valid:
 * +123456789
 * +123456789012345
 *
 * // not valid:
 * +1 23456789
 * 123456789
 * +1234567890123456
 */
const isValidPhoneNumber = (phoneNumber) => {
    return typeof phoneNumber === 'string' && /^\+\d{9,15}$/.test(phoneNumber);
};
exports.isValidPhoneNumber = isValidPhoneNumber;
/**
 * Matches all UUID types, case-insensitive (matches both uppercase and
 * lowercase hexadecimal digits).
 */
const isValidUuid = (uuid) => {
    const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
    return typeof uuid === 'string' && uuidRegex.test(uuid);
};
exports.isValidUuid = isValidUuid;
const isNumber = (x) => {
    return typeof x === 'number';
};
exports.isNumber = isNumber;
const isWholeNumber = (value) => {
    return value % 1 === 0;
};
exports.isWholeNumber = isWholeNumber;
const customExerciseTemplateToExerciseTemplate = (cet) => ({
    id: cet.id,
    title: cet.title,
    priority: 10,
    muscle_group: cet.muscle_group,
    other_muscles: cet.other_muscles || [],
    exercise_type: cet.exercise_type,
    equipment_category: cet.equipment_category,
    is_custom: true,
    is_archived: cet.is_archived,
    custom_exercise_image_url: cet.custom_exercise_image_url,
    thumbnail_url: cet.thumbnail_url,
    parent_exercise_template_id: cet.parent_exercise_template_id,
    volume_doubling_supported: cet.volume_doubling_supported,
});
exports.customExerciseTemplateToExerciseTemplate = customExerciseTemplateToExerciseTemplate;
/**
 * Return true is value is of format: NN:NN or NN:NN:NN
 */
const isValidFormattedTime = (value) => {
    if (value.length !== 5 && value.length !== 8)
        return false;
    if (value.length === 5) {
        return (/[0-9]/.test(value[0]) &&
            /[0-9]/.test(value[1]) &&
            value[2] === ':' &&
            /[0-9]/.test(value[3]) &&
            /[0-9]/.test(value[4]));
    }
    if (value.length === 8) {
        return (/[0-9]/.test(value[0]) &&
            /[0-9]/.test(value[1]) &&
            value[2] === ':' &&
            /[0-9]/.test(value[3]) &&
            /[0-9]/.test(value[4]) &&
            value[5] === ':' &&
            /[0-9]/.test(value[6]) &&
            /[0-9]/.test(value[7]));
    }
    return false;
};
exports.isValidFormattedTime = isValidFormattedTime;
const formatDurationInput = (value) => {
    if ((0, exports.isValidFormattedTime)(value))
        return value;
    if (!/^[0-9:]+$/i.test(value)) {
        return undefined;
    }
    if (value.length === 0) {
        return value;
    }
    if (value === '0') {
        return '';
    }
    if (value.length === 1) {
        return `00:0${value}`;
    }
    if (value.length === 4 &&
        value[0] === '0' &&
        value[1] === '0' &&
        value[3] === '0') {
        // 00:0 -> ''
        return '';
    }
    if (value.length === 4) {
        // backspace
        // 12:3 -> 01:23
        return `0${value[0]}:${value[1]}${value[3]}`;
    }
    if (value.length === 6 && value[0] === '0') {
        // 01:234 -> 12:34
        return `${value[1]}${value[3]}:${value[4]}${value[5]}`;
    }
    if (value.length === 7 && value[0] === '0') {
        // backspace
        // 01:23:4 -> 12:34
        return `${value[1]}${value[3]}:${value[4]}${value[6]}`;
    }
    if (value.length === 7) {
        // backspace
        // 12:34:5 -> 01:23:45
        return `0${value[0]}:${value[1]}${value[3]}:${value[4]}${value[6]}`;
    }
    if (value.length === 6 && value[0] !== '0') {
        // 12:345 -> 01:23:45
        return `0${value[0]}:${value[1]}${value[3]}:${value[4]}${value[5]}`;
    }
    if (value.length === 9) {
        // 01:23:456 -> 12:34:56
        return `${value[1]}${value[3]}:${value[4]}${value[6]}:${value[7]}${value[8]}`;
    }
    return undefined;
};
exports.formatDurationInput = formatDurationInput;
/**
 * Will always return a number. Ivalid strings return 0
 */
const forceStringToNumber = (value) => {
    const numOrNaN = Number(value ? value.replace(',', '.') : 0);
    return numOrNaN ? numOrNaN : 0;
};
exports.forceStringToNumber = forceStringToNumber;
/**
 * Returns a number or undefined
 */
const stringToNumber = (value) => {
    if (!value)
        return undefined;
    const numOrNaN = Number(value ? value.replace(',', '.') : 0);
    return isNaN(numOrNaN) ? undefined : numOrNaN;
};
exports.stringToNumber = stringToNumber;
/**
 * Returns the first non-undefined value produced by transform function being
 * applied to elements of the array in iteration order, or `undefined` if no
 * such value was produced. Equivalent to `firstNotNullOfOrNull` in Kotlin.
 */
const findMapped = (array, transform) => {
    for (const element of array) {
        const result = transform(element);
        if (result !== undefined) {
            return result;
        }
    }
};
exports.findMapped = findMapped;
/**
 * Finds the closest data point that occurs before or at the target date using binary search.
 * Assumes input array is sorted by date in ascending order.
 *
 * @param data - Array of items containing dates in ascending order
 * @param dateExtractor - Function to extract Date from each item
 * @param targetDate - Target date to search for
 * @returns The closest item before or at the target date, or undefined if not found
 *
 * @example
 * const data = [
 *   { id: 1, date: dayjs('2023-01-01').toDate() },
 *   { id: 2, date: dayjs('2023-04-01').toDate() }
 * ];
 * const result = getClosestDataPointBeforeTargetDate(
 *   data,
 *   item => item.date,
 *   dayjs('2023-03-15').toDate(),
 * ); // Returns { id: 1, date: Date('2023-01-01') }
 */
const getClosestDataPointBeforeTargetDate = (data, dateExtractor, targetDate) => {
    var _a;
    if (!data.length)
        return undefined;
    let lowerIndex = 0;
    let upperIndex = data.length - 1;
    let middleIndex;
    do {
        middleIndex = Math.floor((lowerIndex + upperIndex) / 2);
        const middle = data[middleIndex];
        if (dateExtractor(middle) < targetDate) {
            lowerIndex = middleIndex + 1;
            continue;
        }
        else if (dateExtractor(middle) > targetDate) {
            upperIndex = middleIndex - 1;
            continue;
        }
        else {
            // found exact match
            return middle;
        }
    } while (lowerIndex <= upperIndex);
    if (dateExtractor(data[middleIndex]) < targetDate) {
        // the last closest match that was found is before the target
        return data[middleIndex];
    }
    else {
        // the last closest match that was found is just after the target, so take the previous one
        return (_a = data[middleIndex - 1]) !== null && _a !== void 0 ? _a : undefined;
    }
};
exports.getClosestDataPointBeforeTargetDate = getClosestDataPointBeforeTargetDate;
/**
 * Finds the closest data point to the target date.
 *
 * @param data - Array of items containing dates
 * @param dateExtractor - Function to extract Date from each item
 * @param targetDate - Target date to search for
 * @returns The closest item to the target date, or undefined if array is empty
 *
 * @example
 * const data = [
 *   { id: 1, date: dayjs('2023-01-01').toDate() },
 *   { id: 2, date: dayjs('2023-04-01').toDate() }
 * ];
 * const result = getClosestDataPoint(
 *   data,
 *   item => item.date,
 *   dayjs('2023-03-15').toDate(),
 * ); // Returns { id: 2, date: Date('2023-04-01') }
 */
const getClosestDataPointAroundTargetDate = (data, dateExtractor, targetDate) => {
    if (!data.length)
        return undefined;
    return data.reduce((closest, current) => {
        if (!closest)
            return current;
        const closestDiff = Math.abs(dateExtractor(closest).getTime() - targetDate.getTime());
        const currentDiff = Math.abs(dateExtractor(current).getTime() - targetDate.getTime());
        return currentDiff < closestDiff ? current : closest;
    }, undefined);
};
exports.getClosestDataPointAroundTargetDate = getClosestDataPointAroundTargetDate;
const removeAccents = (str) => {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
};
exports.removeAccents = removeAccents;
const workoutDurationSeconds = (workout) => {
    const duration = workout.end_time - workout.start_time;
    return duration >= 0 ? duration : 0;
};
exports.workoutDurationSeconds = workoutDurationSeconds;
const workoutReps = (workout) => {
    return workout.exercises.reduce((exerciseAccu, exercise) => {
        const exerciseReps = exercise.sets.reduce((setAccu, set) => {
            if (set.reps) {
                return setAccu + set.reps;
            }
            return setAccu;
        }, 0);
        return exerciseAccu + exerciseReps;
    }, 0);
};
exports.workoutReps = workoutReps;
const workoutDistanceMeters = (workout) => {
    return workout.exercises.reduce((exerciseAccu, exercise) => {
        const exerciseDistance = exercise.sets.reduce((setAccu, set) => {
            if (set.distance_meters) {
                return setAccu + set.distance_meters;
            }
            return setAccu;
        }, 0);
        return exerciseAccu + exerciseDistance;
    }, 0);
};
exports.workoutDistanceMeters = workoutDistanceMeters;
/**
 * Calculate the set weight for a given user exercise set
 * to be used in the exercise stats calculations on the web and coach app
 */
const userExerciseSetWeight = (set, exerciseStore) => {
    if (!set)
        return 0;
    const exercise = exerciseStore.find((e) => e.id === set.exercise_template_id);
    if (!exercise) {
        return (0, exports.num)(set.weight_kg);
    }
    if (exercise.exercise_type === 'bodyweight_reps') {
        return (0, exports.num)(set.user_bodyweight_kg) + (0, exports.num)(set.weight_kg);
    }
    else if (exercise.exercise_type === 'bodyweight_assisted_reps') {
        return Math.max((0, exports.num)(set.user_bodyweight_kg) - (0, exports.num)(set.weight_kg), 0);
    }
    else {
        return (0, exports.num)(set.weight_kg);
    }
};
exports.userExerciseSetWeight = userExerciseSetWeight;
const workoutSetCount = (w) => {
    return w.exercises.reduce((accu, exercise) => {
        return accu + exercise.sets.length;
    }, 0);
};
exports.workoutSetCount = workoutSetCount;
exports.oneRepMaxPercentageMap = {
    1: 1.0,
    2: 0.97,
    3: 0.94,
    4: 0.92,
    5: 0.89,
    6: 0.86,
    7: 0.83,
    8: 0.81,
    9: 0.78,
    10: 0.75,
    11: 0.73,
    12: 0.71,
    13: 0.7,
    14: 0.68,
    15: 0.67,
    16: 0.65,
    17: 0.64,
    18: 0.63,
    19: 0.61,
    20: 0.6,
    21: 0.59,
    22: 0.58,
    23: 0.57,
    24: 0.56,
    25: 0.55,
    26: 0.54,
    27: 0.53,
    28: 0.52,
    29: 0.51,
    30: 0.5,
};
const oneRepMax = (weight, reps) => {
    if (reps === 0) {
        return 0;
    }
    const percent = reps > 30 ? 0.5 : exports.oneRepMaxPercentageMap[reps];
    return weight / percent;
};
exports.oneRepMax = oneRepMax;
const setVolume = (weight, reps) => {
    return weight * reps;
};
exports.setVolume = setVolume;
/** @deprecated use `numberToLocaleString` */
const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};
exports.numberWithCommas = numberWithCommas;
/**
 * Formats a number into a string, accounting for the system locale.
 *
 * @example
 * numberToLocaleString(1234.567) === '1,234.567' // English (UK / US)
 * numberToLocaleString(1234.567) === '1.234,567' // German (Germany)
 * numberToLocaleString(1234.567) === '1 234,567' // French (France)
 */
const numberToLocaleString = (value, options) => value.toLocaleString(undefined, Object.assign({}, options));
exports.numberToLocaleString = numberToLocaleString;
exports.comparableExerciseTemplateIds = new Set([
    '79D0BB3A' /* Bench Press (Barbell) **/,
    'D04AC939' /* Squat (Barbell) **/,
    'A5AC6449' /* Bicep Curl (Barbell) **/,
    '5F4E6DD3' /* Deadlift (Dumbbell) **/,
    'D20D7BBE' /* Sumo Deadlift **/,
    '50DFDFAB' /* Incline Bench (Barbell) **/,
    '55E6546F' /* Bent Over Row (Barbell) **/,
    'C6272009' /* Deadlift (Barbell) **/,
    '7B8D84E8' /* Overhead Press (Barbell) **/,
    'C7973E0E' /* Leg Press **/,
    'DA0F0470' /* Decline Bench (Barbell) **/,
    '5046D0A9' /* Front Squat **/,
    '1283BBA6' /* Full Squat **/,
    '6622E5A0' /* Sumo Squat (Barbell) **/,
    'FE389074' /* Rack Pull **/,
    '92B8C7E1' /* Hip Thrust **/,
    '1B2B1E7C' /* Pull Up **/,
    '392887AA' /* Push Up **/,
    '29083183' /* Chin Up **/,
    '7AB9A362' /* Upright Row (Barbell) **/,
    '90E506D5' /* Thruster (Barbell) **/,
    '2A48E443' /* Straight Leg Deadlift **/,
    '073032BB' /* Standing Military Press (Barbell) **/,
    '9694DA61' /* Squat (Bodyweight) **/,
    'FB09C938' /* Snatch **/,
    '875F585F' /* Skullcrusher (Barbell) **/,
    '022DF610' /* Sit Up **/,
    '0B841777' /* Shrug (Barbell) **/,
    '91AF29E0' /* Seated Overhead Press (Barbell) **/,
    '2B4B7310' /* Romanian Deadlift (Barbell) **/,
    '818BA121' /* Reverse Lunge (Barbell) **/,
    '542F3CD5' /* Push Press **/,
    'E22F9358' /* Power Snatch **/,
    'C628D768' /* Power Clean **/,
    '3FF6A22E' /* Pistol Squat **/,
    '0EFE8162' /* Pike Pushup **/,
    '018ADC12' /* Pendlay Row (Barbell) **/,
    '6E6EE645' /* Lunge (Barbell) **/,
    '5E1A7777' /* Lunge **/,
    'B74A95BB' /* Kneeling Push Up **/,
    '70D4EBBF' /* Jump Squat **/,
    'D57C2EC7' /* Hip Thrust (Barbell) **/,
    '4180C405' /* Good Morning (Barbell) **/,
    '6575F52D' /* Diamond Push Up **/,
    'DCF3B31B' /* Crunch **/,
    '652FEA39' /* Clean Pull **/,
    'D3095577' /* Clean and Press **/,
    '9E09CEC3' /* Clean and Jerk **/,
    'ABB00838' /* Clean **/,
    'BB792A36' /* Burpee **/,
    'E644F828' /* Bench Press - Wide Grip (Barbell) **/,
    '35B51B87' /* Bench Press - Close Grip (Barbell) **/,
]);
const isCompareExerciseSupported = (temlpateId) => {
    return exports.comparableExerciseTemplateIds.has(temlpateId);
};
exports.isCompareExerciseSupported = isCompareExerciseSupported;
/**
 * Weight&Reps exercises that are also comparable (38 exercises at the moment)
 * These exercises are used in the leaderboard
 */
exports.leaderboardEligibleExerciseTemplateIds = [
    '79D0BB3A' /* Bench Press (Barbell) **/,
    'D04AC939' /* Squat (Barbell) **/,
    'C6272009' /* Deadlift (Barbell) **/,
    '7B8D84E8' /* Overhead Press (Barbell) **/,
    '55E6546F' /* Bent Over Row (Barbell) **/,
    'A5AC6449' /* Bicep Curl (Barbell) **/,
    '875F585F' /* Skullcrusher (Barbell) **/,
    'C7973E0E' /* Leg Press **/,
    '5F4E6DD3' /* Deadlift (Dumbbell) **/,
    'D20D7BBE' /* Sumo Deadlift **/,
    '50DFDFAB' /* Incline Bench (Barbell) **/,
    'DA0F0470' /* Decline Bench (Barbell) **/,
    '5046D0A9' /* Front Squat **/,
    '1283BBA6' /* Full Squat **/,
    '6622E5A0' /* Sumo Squat (Barbell) **/,
    'FE389074' /* Rack Pull **/,
    '7AB9A362' /* Upright Row (Barbell) **/,
    '90E506D5' /* Thruster (Barbell) **/,
    '2A48E443' /* Straight Leg Deadlift **/,
    '073032BB' /* Standing Military Press (Barbell) **/,
    'FB09C938' /* Snatch **/,
    '0B841777' /* Shrug (Barbell) **/,
    '91AF29E0' /* Seated Overhead Press (Barbell) **/,
    '2B4B7310' /* Romanian Deadlift (Barbell) **/,
    '818BA121' /* Reverse Lunge (Barbell) **/,
    '542F3CD5' /* Push Press **/,
    'E22F9358' /* Power Snatch **/,
    'C628D768' /* Power Clean **/,
    '018ADC12' /* Pendlay Row (Barbell) **/,
    '6E6EE645' /* Lunge (Barbell) **/,
    'D57C2EC7' /* Hip Thrust (Barbell) **/,
    '4180C405' /* Good Morning (Barbell) **/,
    '652FEA39' /* Clean Pull **/,
    'D3095577' /* Clean and Press **/,
    '9E09CEC3' /* Clean and Jerk **/,
    'ABB00838' /* Clean **/,
    'E644F828' /* Bench Press - Wide Grip (Barbell) **/,
    '35B51B87' /* Bench Press - Close Grip (Barbell) **/,
];
const isExerciseLeaderboardEligible = (templateId) => {
    return (typeof templateId === 'string' &&
        exports.leaderboardEligibleExerciseTemplateIds.includes(templateId));
};
exports.isExerciseLeaderboardEligible = isExerciseLeaderboardEligible;
/**
 * Weight&Reps exercises that are also strength level eligible (3 exercises at the moment)
 * These exercises are used in the strength level calculation
 **/
exports.strengthLevelEligibleExerciseTemplateIds = [
    '79D0BB3A' /* Bench Press (Barbell) **/,
    'D04AC939' /* Squat (Barbell) **/,
    'C6272009' /* Deadlift (Barbell) **/,
];
const isStrengthLevelEligibleExercise = (templateId) => {
    return (typeof templateId === 'string' &&
        exports.strengthLevelEligibleExerciseTemplateIds.includes(templateId));
};
exports.isStrengthLevelEligibleExercise = isStrengthLevelEligibleExercise;
const getStrengthLevelFromPercentile = (percentile) => {
    if (percentile >= 90) {
        return 'elite';
    }
    else if (percentile >= 70) {
        return 'advanced';
    }
    else if (percentile >= 40) {
        return 'intermediate';
    }
    else {
        return 'beginner';
    }
};
exports.getStrengthLevelFromPercentile = getStrengthLevelFromPercentile;
const isExerciseTemplate = (x) => {
    return (x.id !== undefined &&
        x.title !== undefined &&
        x.muscle_group !== undefined &&
        x.exercise_type !== undefined &&
        x.equipment_category !== undefined);
};
exports.isExerciseTemplate = isExerciseTemplate;
const _v4uuidCheckRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-([0-9a-f])[0-9a-f]{3}-([0-9a-f])[0-9a-f]{3}-([0-9a-f]{12})/i;
/**
 * Generates a subsample or test group given a user id. Technically, it just
 * calculates a trivial checksum of the last (random) part of a v4 UUID.
 * @param userId a v4 UUID; _must_ be v4 to ensure random distribution
 * @param numGroups number of possible groups, from 2 to 2^32
 * @returns a number in the `[0, numGroups)` range, always equal for a given
 * `(userId, numGroups)` pair, or an error, inside a Result<T> object
 */
const generateUserGroup = (userId, numGroups) => {
    if (numGroups < 2 || numGroups > 2 ** 32) {
        return { isSuccess: false, error: 'invalid-number-of-groups' };
    }
    const match = _v4uuidCheckRegex.exec(userId);
    if (!match) {
        return { isSuccess: false, error: 'invalid-uuid' };
    }
    const [, version, variant, tail] = match;
    if (version !== '4') {
        return { isSuccess: false, error: 'uuid-not-v4' };
    }
    if (!['8', '9', 'a', 'b', 'c', 'd'].includes(variant.toLowerCase())) {
        return { isSuccess: false, error: 'invalid-variant' };
    }
    return {
        isSuccess: true,
        value: Number(BigInt(`0x${tail}`) % BigInt(numGroups)),
    };
};
exports.generateUserGroup = generateUserGroup;
/**
 * @example
 * isVersionAGreaterOrEqualToVersionB('1.2.3', '1.2')    // true
 * isVersionAGreaterOrEqualToVersionB('1.2.3', '1.2.2')  // true
 * isVersionAGreaterOrEqualToVersionB('1.2.3', '1.2.3')  // true
 *
 * isVersionAGreaterOrEqualToVersionB('1.2.3', '1.2.4')  // false
 * isVersionAGreaterOrEqualToVersionB('1.2.3', '1.3')    // false
 */
const isVersionAGreaterOrEqualToVersionB = (versionA, versionB) => {
    var _a, _b;
    const versionAparts = `${versionA}`.split('.');
    const versionBparts = `${versionB}`.split('.');
    const longestVersionLength = Math.max(versionAparts.length, versionBparts.length);
    for (let i = 0; i < longestVersionLength; i++) {
        const versionApart = (_a = versionAparts[i]) !== null && _a !== void 0 ? _a : 0;
        const versionBpart = (_b = versionBparts[i]) !== null && _b !== void 0 ? _b : 0;
        if (parseInt(versionApart, 10) > parseInt(versionBpart, 10)) {
            return true;
        }
        if (parseInt(versionApart, 10) < parseInt(versionBpart, 10)) {
            return false;
        }
        // if equal, continue to next part
    }
    // the versions are entirely equal
    return true;
};
exports.isVersionAGreaterOrEqualToVersionB = isVersionAGreaterOrEqualToVersionB;
const splitAtUsernamesAndLinks = (text) => {
    const mentionsRegex = /\B@[a-z0-9_-]+/gi;
    const linksRegex = exports.URL_REGEX;
    const regex = new RegExp(`(${mentionsRegex.source}|${linksRegex.source})`, 'gi');
    const matches = text.match(regex);
    const format = [];
    if (!matches) {
        return [{ format: 'none', text }];
    }
    let lastIndex = 0;
    matches.forEach((match) => {
        const index = text.indexOf(match, lastIndex);
        if (index > lastIndex) {
            format.push({ format: 'none', text: text.substring(lastIndex, index) });
        }
        if (match.match(mentionsRegex)) {
            format.push({ format: '@', text: match });
        }
        else if (match.match(linksRegex)) {
            format.push({ format: 'link', text: match });
        }
        lastIndex = index + match.length;
    });
    if (lastIndex < text.length) {
        format.push({ format: 'none', text: text.substring(lastIndex) });
    }
    return format;
};
exports.splitAtUsernamesAndLinks = splitAtUsernamesAndLinks;
// Single comprehensive YouTube URL regex for both validation and ID extraction
const YOUTUBE_URL_REGEX = /^(?:https?:\/\/)?(?:(?:www\.)?youtube\.com\/(?:watch\?v=|shorts\/|embed\/|v\/|user\/[^/]+#p\/|attribution_link\?a=|channel\/|c\/|playlist\?list=)|youtu\.be\/)([a-zA-Z0-9_-]{11})(?:[/?#&].*)?$/;
const validateYoutubeUrl = (url) => {
    return YOUTUBE_URL_REGEX.test(url);
};
exports.validateYoutubeUrl = validateYoutubeUrl;
const getYoutubeVideoId = (url) => {
    const match = url.match(YOUTUBE_URL_REGEX);
    return match && match[1] && match[1].length === 11 ? match[1] : undefined;
};
exports.getYoutubeVideoId = getYoutubeVideoId;
