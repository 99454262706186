import { AmplitudeClient } from 'amplitude-js';
import { env } from 'env';
import { log } from './log';

export const sendEvent = (event: string, params?: Record<string, any>) => {
  log(`👣 amplitudeEvent: ${event} params: `, params);

  if (env.env === 'production') {
    const webPrefix = '[WEB]-';
    amplitudeEvent(`${webPrefix}${event}`, params);
  }
};

/**
 * Amplitude
 */

let amplitude: { getInstance: () => AmplitudeClient } | undefined;

export const initAmplitude = () => {
  if (typeof window) {
    log('initAmplitude called');
    // eslint-disable-next-line @typescript-eslint/no-require-imports
    amplitude = require('amplitude-js');
    amplitude?.getInstance().init('57b5a5fe4eae0e7b251e6b5daccf0480');
  }
};

export const setAmplitudeUserId = (userId: string) => {
  if (typeof window) {
    amplitude?.getInstance().setUserId(userId);
  }
};

export const updateAmplitudeUserProperties = (userProperties: Record<string, any>) => {
  if (!!window && typeof window && !!amplitude) {
    const identifyObj = new (amplitude.getInstance().Identify)();

    Object.keys(userProperties).forEach(key => {
      identifyObj.set(key, userProperties[key]);
    });

    amplitude.getInstance().identify(identifyObj);
    log(`Updated Amplitude user properties: ${JSON.stringify(userProperties, null, 2)}`);
  }
};

const amplitudeEvent = (name: string, params?: Record<string, any>) => {
  if (typeof window) {
    amplitude?.getInstance().logEvent(name, params);
  }
};
