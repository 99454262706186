import {
  EquipmentFilter,
  MuscleGroupFilter,
  MuscleGroup,
  Equipment,
  CustomExerciseType,
} from 'hevy-shared';

export const customExerciseTypeToHuman = (type: CustomExerciseType): string => {
  const customExerciseTypeToHumanMap: { [key in CustomExerciseType]: string } = {
    weight_reps: 'Weight Reps',
    bodyweight_reps: 'Weighted Bodyweight',
    bodyweight_assisted_reps: 'Assisted Bodyweight',
    reps_only: 'Reps Only',
    duration: 'Duration',
    weight_duration: 'Weight & Duration',
    distance_duration: 'Distance & Duration',
    short_distance_weight: 'Weight & Distance',
  };

  return customExerciseTypeToHumanMap[type];
};

export const equipmentToHuman = (equipment: Equipment): string => {
  const equipmentToHumanMap: { [key in Equipment]: string } = {
    none: 'None',
    machine: 'Machine',
    kettlebell: 'Kettlebell',
    barbell: 'Barbell',
    dumbbell: 'Dumbbell',
    plate: 'Plate',
    resistance_band: 'Resistance Band',
    suspension: 'Suspension',
    other: 'Other',
  };

  return equipmentToHumanMap[equipment];
};

export const equipmentFilterToHuman = (equipmentFilter: EquipmentFilter): string => {
  const equipmentFilterToHumanMap: { [key in EquipmentFilter]: string } = {
    all_equipment: 'All Equipment',
    none: 'None',
    machine: 'Machine',
    kettlebell: 'Kettlebell',
    barbell: 'Barbell',
    dumbbell: 'Dumbbell',
    plate: 'Plate',
    resistance_band: 'Resistance Band',
    suspension: 'Suspension',
    other: 'Other',
  };

  return equipmentFilterToHumanMap[equipmentFilter];
};

export const muscleGroupToHuman = (muscleGroup: MuscleGroup): string => {
  const muscleGroupToHuman: { [key in MuscleGroup]: string } = {
    abdominals: 'Abdominals',
    cardio: 'Cardio',
    shoulders: 'Shoulders',
    chest: 'Chest',
    lower_back: 'Lower Back',
    full_body: 'Full Body',
    biceps: 'Biceps',
    triceps: 'Triceps',
    upper_back: 'Upper Back',
    quadriceps: 'Quadriceps',
    calves: 'Calves',
    lats: 'Lats',
    hamstrings: 'Hamstrings',
    glutes: 'Glutes',
    abductors: 'Abductors',
    adductors: 'Adductors',
    traps: 'Traps',
    forearms: 'Forearms',
    neck: 'Neck',
    other: 'Other',
  };

  return muscleGroupToHuman[muscleGroup];
};

export const muscleGroupFilterToHuman = (muscleGroupFilter: MuscleGroupFilter): string => {
  const muscleGroupFilterToHuman: { [key in MuscleGroupFilter]: string } = {
    all_muscles: 'All Muscles',
    abdominals: 'Abdominals',
    cardio: 'Cardio',
    shoulders: 'Shoulders',
    chest: 'Chest',
    lower_back: 'Lower Back',
    full_body: 'Full Body',
    biceps: 'Biceps',
    triceps: 'Triceps',
    upper_back: 'Upper Back',
    quadriceps: 'Quadriceps',
    calves: 'Calves',
    lats: 'Lats',
    hamstrings: 'Hamstrings',
    glutes: 'Glutes',
    abductors: 'Abductors',
    adductors: 'Adductors',
    traps: 'Traps',
    forearms: 'Forearms',
    neck: 'Neck',
    other: 'Other',
  };

  return muscleGroupFilterToHuman[muscleGroupFilter];
};
