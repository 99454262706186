import React from 'react';
import { ProfileImage } from 'components/ProfileImage';
import dayjs from 'dayjs';
import { Workout, WorkoutComment } from 'hevy-shared';
import { Link } from 'components/Link';
import { stores } from 'state/stores';
import styled from 'styled-components';
import { TextSMMedium, TextSMRegular, TextXSRegular } from 'styleguide/Texts';
import { View } from 'styleguide/View';
import { getRelativeDate } from 'utils/misc';
import { CommentOptionsMenu } from './CommentOptionsMenu';
import { Spacing } from 'styleguide/spacing';
import { modal } from 'components/Modals/ModalManager';
import { LinkableText } from 'components/LinkableText';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spacing.md}px;
  word-break: break-word;
`;

const CommentContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

interface CommentsProps {
  comments: WorkoutComment[];
  workout?: Workout;
  onCommentDelete: (commentId: number) => void;
  onUserClick?: () => void;
  showAllComments?: boolean;
}

export const Comments = ({
  comments,
  workout,
  onCommentDelete,
  onUserClick,
  showAllComments,
}: CommentsProps) => {
  const commentsToShow = showAllComments ? comments : comments.slice(0, 2);

  return (
    <Container>
      {commentsToShow.map(c => (
        <Comment
          key={c.id}
          comment={c}
          onCommentDelete={() => onCommentDelete(c.id)}
          onUserClick={onUserClick}
        />
      ))}
      {comments.length > 2 && !showAllComments && !!workout && (
        <TextSMRegular
          style={{ cursor: 'pointer' }}
          onClick={() => {
            modal.openCommentModal(workout.id);
          }}
        >
          View all {comments.length} comments
        </TextSMRegular>
      )}
    </Container>
  );
};

interface CommentProps {
  comment: WorkoutComment;
  onCommentDelete: () => void;
  onUserClick?: () => void;
}

const Comment = ({ comment, onCommentDelete, onUserClick }: CommentProps) => {
  return (
    <CommentContainer key={`${comment.id}`}>
      <Link href={`/user/${comment.username}`} onClick={onUserClick}>
        <ProfileImage source={comment.profile_pic} diameter={48} />
      </Link>
      <View style={{ flex: 1, justifyContent: 'center', marginLeft: Spacing.md }}>
        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <Link href={`/user/${comment.username}`} onClick={onUserClick}>
            <TextSMMedium style={{ marginBottom: Spacing.xs }}>{comment.username}</TextSMMedium>
          </Link>
          <TextXSRegular>{getRelativeDate(dayjs(comment.created_at))}</TextXSRegular>
        </View>

        <View
          style={{
            flex: 1,
            height: 30,
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <LinkableText
            text={comment.comment}
            enableLinks
            enableMentions
            onMentionClick={onUserClick}
          />
          {comment.username === stores.account.username && (
            <View>
              <CommentOptionsMenu onDeleteClick={onCommentDelete} />
            </View>
          )}
        </View>
      </View>
    </CommentContainer>
  );
};
