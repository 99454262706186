import React, { CSSProperties } from 'react';
import { TextSMRegular } from 'styleguide/Texts';
import { colors } from 'styleguide/colors';
import { Link } from './Link';
import { formatLink } from 'utils/misc';
import { splitAtUsernamesAndLinks } from 'hevy-shared';

interface LinkableTextProps {
  text?: string;
  style?: CSSProperties;
  onMentionClick?: () => void;
  textStyle?: CSSProperties;
  enableLinks?: boolean;
  enableMentions?: boolean;
}

export const LinkableText = ({
  text,
  style,
  textStyle,
  onMentionClick,
  enableLinks,
  enableMentions,
}: LinkableTextProps) => {
  const format = splitAtUsernamesAndLinks(text || '');

  return (
    <div style={{ ...style }}>
      {format.map((f, index) => {
        if (f.format === '@' && enableMentions) {
          return (
            <Link href={`/user/${f.text.substring(1)}`} onClick={onMentionClick} key={index}>
              <TextSMRegular style={{ ...textStyle, color: colors.primary500, display: 'inline' }}>
                {f.text}
              </TextSMRegular>
            </Link>
          );
        }

        if (f.format === 'link' && enableLinks) {
          return (
            <a
              href={formatLink(f.text)}
              style={{ textDecoration: 'none' }}
              target="_blank"
              key={index}
              rel="noreferrer"
            >
              <TextSMRegular style={{ ...textStyle, color: colors.primary500, display: 'inline' }}>
                {f.text}
              </TextSMRegular>
            </a>
          );
        }

        return (
          <TextSMRegular key={index} type="secondary" style={{ ...textStyle, display: 'inline' }}>
            {f.text}
          </TextSMRegular>
        );
      })}
    </div>
  );
};
