import {
  equipments,
  customExericseTypes,
  isEquipment,
  isExerciseType,
  isMuscleGroup,
  MuscleGroup,
  muscleGroups,
} from 'hevy-shared';
import { makeAutoObservable } from 'mobx';
import { v4 as uuid } from 'uuid';
import { stores } from 'state/stores';
import {
  customExerciseTypeToHuman,
  equipmentToHuman,
  muscleGroupToHuman,
} from 'utils/exerciseUtils';
import { getUrlFromFile, resizeImage } from 'utils/imageUtils';
import { uploadImage } from 'utils/uploadImage';
import toast from 'react-hot-toast';
import API from 'utils/API';
import { fireAndForget } from 'utils/async';
import axios from 'axios';

export class CustomExerciseViewModel {
  imageUrlToCrop?: string;
  isUploadingImage: boolean = false;
  isSaving: boolean = false;
  remoteThumbnailUrl?: string;
  remoteImageUrl?: string;

  constructor() {
    makeAutoObservable(this);
  }

  get isSaveEnabled(): boolean {
    return (
      !this.isSaving &&
      !!this.title.trim().length &&
      !!this.selectedEquipment &&
      !!this.selectedExerciseType &&
      !!this.selectedMuslceGroup
    );
  }

  onSave = async (): Promise<string> => {
    const muscleGroup = this.selectedMuslceGroup?.value;
    const exerciseType = this.selectedExerciseType?.value;
    const equipment = this.selectedEquipment?.value;
    const otherMuscles: MuscleGroup[] = this.selectedSecondaryMuscleGroups.map(
      mg => mg.value,
    ) as any;

    if (
      !muscleGroup ||
      !equipment ||
      !isMuscleGroup(muscleGroup) ||
      !isExerciseType(exerciseType) ||
      !isEquipment(equipment)
    ) {
      throw new Error('InvalidExerciseDetails');
    }

    this.isSaving = true;
    try {
      const response = await API.postCustomExerciseTemplate({
        title: this.title,
        muscle_group: muscleGroup,
        other_muscles: otherMuscles,
        exercise_type: exerciseType,
        equipment_category: equipment,
        custom_exercise_image_url: this.remoteImageUrl,
        thumbnail_url: this.remoteThumbnailUrl,
      });

      this.title = '';
      this.selectedMuslceGroup = undefined;
      this.selectedExerciseType = undefined;
      this.selectedEquipment = undefined;
      this.selectedSecondaryMuscleGroups = [];

      await fireAndForget([stores.exerciseTemplates.fetch()]);
      return response.data.id;
    } catch (e) {
      if (axios.isAxiosError(e) && e.response?.data.error === 'exceeds-custom-exercise-limit') {
        toast.error(
          'Free accounts are limited to 7 custom exercises. Upgrade to Hevy Pro to get unlimited',
        );
      } else {
        toast.error('Failed to save exercise');
      }

      throw e;
    } finally {
      this.isSaving = false;
    }
  };

  onDismissImageCrop = () => {
    this.isUploadingImage = false;
    this.imageUrlToCrop = undefined;
  };

  onFileSystemImageSelected = async (file: File) => {
    this.isUploadingImage = true;
    const { url } = await getUrlFromFile(file);

    this.imageUrlToCrop = url;
  };

  onCancelImageCrop = () => {
    this.imageUrlToCrop = undefined;
    this.isUploadingImage = false;
  };

  onUploadImage = async (imageUrl: string) => {
    this.imageUrlToCrop = undefined;
    try {
      const thumbnailFileName = `exercise-thumbnail-${stores.account.username}-${uuid()}.jpg`;
      const fileName = `exercise-${stores.account.username}-${uuid()}.jpg`;

      const thumbnailImageUrl = await resizeImage(imageUrl, { width: 180, height: 180 });

      const { url: remoteThumbnailUrl } = await uploadImage(thumbnailFileName, thumbnailImageUrl);
      const { url: remoteImageUrl } = await uploadImage(fileName, imageUrl);

      this.remoteThumbnailUrl = remoteThumbnailUrl;
      this.remoteImageUrl = remoteImageUrl;
    } catch (e) {
      toast.error('Failed to upload image');
    } finally {
      this.isUploadingImage = false;
    }
  };

  title: string = '';
  onTitleUpdate = (value: string) => {
    if (value.length > 250) return;

    this.title = value;
  };

  selectedExerciseType?: { label: string; value: string };
  onExerciseTypeUpdate = (exerciseType: { label: string; value: string } | undefined) => {
    this.selectedExerciseType = exerciseType;
  };

  get exerciseTypeOptions() {
    return customExericseTypes.map(et => ({ label: customExerciseTypeToHuman(et), value: et }));
  }

  selectedEquipment?: { label: string; value: string };
  onEquipmentUpdate = (equipment: { label: string; value: string } | undefined) => {
    this.selectedEquipment = equipment;
  };

  get equipmentOptions() {
    return equipments.map(e => ({ label: equipmentToHuman(e), value: e }));
  }

  selectedMuslceGroup?: { label: string; value: string };
  onMuscleGroupUpdate = (muscleGroup: { label: string; value: string } | undefined) => {
    this.selectedMuslceGroup = muscleGroup;
  };

  get muslceGroupOptions() {
    return muscleGroups.map(mg => ({ label: muscleGroupToHuman(mg), value: mg }));
  }

  selectedSecondaryMuscleGroups: Array<{ label: string; value: string }> = [];
  onSecondaryMuscleGroupUpdate = (value: Array<{ label: string; value: string }>) => {
    this.selectedSecondaryMuscleGroups = value;
  };
  get secondaryMuscleGroupOptions() {
    return muscleGroups.map(mg => ({ label: muscleGroupToHuman(mg), value: mg }));
  }
}
